
<style scoped>
.user-panel {
  margin: 10px auto;
}
</style>
<template>
  <el-dialog
    :visible.sync="showDialog"
    :title="title"
    :modal-append-to-body="false"
    style="text-align: left"
    @close="closeDialog"
    width="800px"
  >
    <div class="user-panel" v-loading="loading">
      <el-form
        ref="form"
        :model="formModel"
        :rules="ruleValidate"
        :label-width="'100px'"
      >
        <el-form-item label="标题" prop="title">
          <el-input
            v-model="formModel.title"
            placeholder="请输入标题"
            style="width: 100%"
          ></el-input>
        </el-form-item>
        <el-form-item label="消息类型" prop="type">
          <el-select
            v-model="formModel.type"
            placeholder="请选择"
            style="width: 100%"
          >
            <el-option
              v-for="item in typeList"
              :key="item.id"
              :label="item.name"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="内容" prop="content">
          <mce-editor
            ref="editor"
            uploadName="upfile"
            v-model="formModel.content"
            :url="uploadUrl"
            :config="editorConfig"
          ></mce-editor>
        </el-form-item>
        <el-form-item label="审核状态" prop="status">
          <el-switch
            v-model="formModel.status"
            active-color="#13ce66"
            active-text="已审核"
            inactive-color="#ff4949"
            inactive-text="未审核"
          ></el-switch>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeDialog">取 消</el-button>
      <el-button type="primary" @click="handleSubmit" :loading="submitting"
        >确 定</el-button
      >
    </span>
  </el-dialog>
</template>
<script>
import Constant from "@/constant";
import messageInfoApi from "@/api/base/messageInfo";
import dataDictionaryApi from "@/api/sys/dataDictionary";
import { getToken } from "@/utils/auth";
import MceEditor from "@/components/Tinymce";

export default {
  props: ["businessKey", "title"],
  data() {
    return {
      ruleValidate: {
        title: [{ required: true, message: "标题不能为空", trigger: "blur" }],
        type: [
          { required: true, message: "通知类型不能为空", trigger: "blur" },
        ],
      },
      showDialog: true,
      loading: false,
      submitting: false,
      formModel: {},
      typeList: [],
      editorConfig: {
        height: 300,
        width: 660,
      },
      uploadUrl: Constant.serverUrl + "/tinymce/upload?token=" + getToken(),
    };
  },
  methods: {
    closeDialog() {
      this.$emit("close", false);
    },
    handleSubmit() {
      var self = this;

      this.$refs["form"].validate((valid) => {
        if (valid) {
          (function () {
            var id = self.formModel.id;

            self.formModel.content = self.$refs.editor.getContent();

            if (id == null || id.length == 0) {
              return messageInfoApi.add(self.formModel);
            } else {
              return messageInfoApi.update(self.formModel);
            }
          })().then(function (response) {
            var jsonData = response.data;

            if (jsonData.result) {
              self.$message({
                message: "保存成功!",
                type: "success",
              });

              self.$emit("close", true);
            } else {
              self.$message({
                message: jsonData.message + "",
                type: "warning",
              });

              self.$emit("close", false);
            }
          });
        }
      });
    },
  },
  mounted: function () {
    var self = this;

    dataDictionaryApi
      .findByCatalogName({
        catalogName: "消息类型",
      })
      .then((response) => {
        var jsonData = response.data;
        this.typeList = jsonData.data;
      });

    (function () {
      if (self.businessKey.length == 0) {
        return messageInfoApi.create();
      } else {
        return messageInfoApi.edit(self.businessKey);
      }
    })()
      .then((response) => {
        var jsonData = response.data;

        self.loading = false;

        if (jsonData.result) {
          self.formModel = jsonData.data;

          if (self.formModel.content != null) {
            self.$refs.editor.setContent(self.formModel.content);
          }
        } else {
          self.$message.error(jsonData.message + "");
        }
      })
      .catch((error) => {
        self.$message.error(error + "");
      });
  },
  components: {
    "mce-editor": MceEditor,
  },
};
</script>