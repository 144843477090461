
<style scoped>
.user-panel {
  margin: 10px auto;
}
</style>
<template>
  <el-dialog
    :visible.sync="showDialog"
    :title="title"
    :modal-append-to-body="true"
    append-to-body
    :close-on-click-modal="false"
    style="text-align: left"
    @close="closeDialog"
  >
    <div class="user-panel" v-loading="loading">
      <el-form
        ref="form"
        :model="formModel"
        :rules="ruleValidate"
        :label-width="'100px'"
      >
        <el-form-item label="所属单位" prop="companyId">
          <el-select-tree
            size="mini"
            :options="companyList"
            v-model="formModel.companyId"
            height="200"
            style="width:300px;"
          ></el-select-tree>
        </el-form-item>
        <el-form-item label="所属机构" prop="structureId">
          <el-select-tree
            size="mini"
            :props="structProps"
            :options="structureList"
            v-model="formModel.companyStructureId"
            height="200"
            style="width:300px;"
            placeholder="不选择则为全部"
          ></el-select-tree>
        </el-form-item>
        <el-form-item label="所属角色" prop="roleId">
          <el-select
            v-model="formModel.personRoleId"
            placeholder="不选择则为全部"
            clearable
            size="mini"
            style="width:300px;"
          >
            <el-option
              :value="item.id"
              v-for="item in roleList"
              :key="item.key"
              :label="item.personRoleName"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeDialog">取 消</el-button>
      <el-button type="primary" @click="handleSubmit" :loading="submitting"
        >确 定</el-button
      >
    </span>
  </el-dialog>
</template>
<script>
import SelectTree from "@/components/SelectTree";
import Constant from "@/constant";
import messageSendConditionApi from "@/api/base/messageSendCondition";
import companyInfoApi from "@/api/base/companyInfo";
import companyStructureInfoApi from "@/api/base/companyStructureInfo";
import personRoleInfoApi from "@/api/base/personRoleInfo";
import dataDictionaryApi from "@/api/sys/dataDictionary";

export default {
  props: ["businessKey", "title", "messageId"],
  data() {
    return {
      ruleValidate: {
        companyId: [
          { required: true, message: "单位不能为空", trigger: "blur" },
        ],
      },
      showDialog: true,
      loading: false,
      submitting: false,
      formModel: {
        companyId: "",
      },
      companyList: [],
      structureList: [],
      roleList: [],
      structProps: {
        // 配置项（必选）
        value: "id",
        label: "structureName",
        children: "children"
      }
    };
  },
  watch: {
    "formModel.companyId": function (newVal, oldVal) {
      var self = this;

      if(newVal!=null){
        var formData = new FormData();
        formData.append("companyId", newVal);

        //查询单位下机构
        companyStructureInfoApi.list(formData).then((response) => {
          var jsonData = response.data;

          if (jsonData.result) {
            self.structureList = jsonData.data;
          }
        });

        //查询单位下角色
        personRoleInfoApi.listByCompanyId(formData).then((response) => {
          var jsonData = response.data;

          if (jsonData.result) {
            self.roleList = jsonData.data;
          }
        });
      }
    },
  },
  methods: {
    closeDialog() {
      this.$emit("close", false);
    },
    handleSubmit() {
      var self = this;

      this.$refs["form"].validate((valid) => {
        if (valid) {
          (function () {
            var id = self.formModel.id;

            self.formModel.messageId = self.messageId;

            if (id == null || id.length == 0) {
              return messageSendConditionApi.add(self.formModel);
            } else {
              return messageSendConditionApi.update(self.formModel);
            }
          })().then(function (response) {
            var jsonData = response.data;

            if (jsonData.result) {
              self.$message({
                message: "保存成功!",
                type: "success",
              });

              self.$emit("close", true);
            } else {
              self.$message({
                message: jsonData.message + "",
                type: "warning",
              });

              self.$emit("close", false);
            }
          });
        }
      });
    },
  },
  mounted: function () {
    var self = this;

    companyInfoApi.treeList().then(function (response) {
      var jsonData = response.data;

      if (jsonData.result) {
        self.companyList = jsonData.data;
      }
    });

    (function () {
      if (self.businessKey.length == 0) {
        return messageSendConditionApi.create();
      } else {
        return messageSendConditionApi.edit(self.businessKey);
      }
    })()
      .then((response) => {
        var jsonData = response.data;
        self.loading = false;

        if (jsonData.result) {
          self.formModel = jsonData.data;
        } else {
          self.$message.error(jsonData.message + "");
        }
      })
      .catch((error) => {
        self.$message.error(error + "");
      });
  },
  components: {
    "el-select-tree": SelectTree
  }
};
</script>