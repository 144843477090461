import request from '@/utils/request'
import constant from '@/constant'

function pageList(formData){
  return request.post(constant.serverUrl + "/base/message/pageList", formData);
}

function create(){
  return request.get(constant.serverUrl + "/base/message/create");
}

function edit(id){
  return request.get(constant.serverUrl + "/base/message/edit/" + id);
}

function add(formModel){
  return request.post(constant.serverUrl + "/base/message/add", formModel,{
    headers: {
      "Content-Type": "application/json"
    }
  });
}

function update(formModel){  
  return request.post(constant.serverUrl + "/base/message/update", formModel,{
    headers: {
      "Content-Type": "application/json"
    }
  });
}

function remove(id){
  return request.post(constant.serverUrl + "/base/message/delete/" + id);
}

function batchRemove(idList){
  return request.post(constant.serverUrl + "/base/message/batchDelete",idList,{
    headers: {
      "Content-Type": "application/json"
    }
  });
}

function recvPageSearch(formData){
  return request.post(constant.serverUrl + "/base/message/recvPageSearch", formData);
}


function batchSend(formData){
  return request.post(constant.serverUrl + "/base/message/batchSend",formData);
}

function sendAll(formData){
  return request.post(constant.serverUrl + "/base/message/sendAll", formData);
}

export default {
  pageList,create,edit,add,update,remove,batchRemove,recvPageSearch,batchSend,sendAll
}