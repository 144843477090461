import request from '@/utils/request'
import constant from '@/constant'

function pageList(formData){
  return request.post(constant.serverUrl + "/base/messageReceiver/pageList", formData);
}

function batchRemove(idList){
  return request.post(constant.serverUrl + "/base/messageReceiver/batchDelete",idList,{
    headers: {
      "Content-Type": "application/json"
    }
  });
}

export default {
  pageList,batchRemove
}