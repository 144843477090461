<template>
  <div>
    <el-form
      ref="queryForm"
      :model="queryModel"
      :rules="ruleValidate"
      inline
      class="demo-form-inline"
    >
      <el-form-item label="所属单位" prop="companyId">
        <el-select-tree
          size="mini"
          :options="companyList"
          v-model="queryModel.companyId"
          height="200"
        ></el-select-tree>
      </el-form-item>
      <el-form-item label="所属机构" prop="structureId">
          <el-select-tree
            size="mini"
            :props="structProps"
            :options="structureList"
            v-model="queryModel.companyStructureId"
            height="200"
          ></el-select-tree>
      </el-form-item>
      <el-form-item label="所属角色" prop="roleId">
        <el-select v-model="queryModel.roleId" placeholder="请选择" clearable size="mini">
          <el-option :value="item.id" v-for="item in roleList" :key="item.key" 
          :label="item.sceneName + '-' + item.personRoleName"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="姓名" prop="personName">
        <el-input v-model="queryModel.personName" size="mini"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          size="mini"
          icon="ios-search"
          @click="changePage(1)"
          :loading="loading"
          >查询</el-button
        >&nbsp;
        <el-button
          type="info"
          size="mini"
          style="margin-left: 8px"
          @click="handleReset('queryForm')"
          >重置</el-button
        >&nbsp;
      </el-form-item>
    </el-form>
    <el-divider></el-divider>
    <el-row class="button-group">
      <el-button
        type="primary"
        size="small"
        plain
        icon="el-icon-circle-plus"
        @click="handleSendAll"
        >全部发送</el-button>
      <el-button
        type="primary"
        size="small"
        plain
        icon="el-icon-circle-plus"
        :disabled="multipleSelection.length == 0"
        @click="handleBatchSend"
        >对选中人发送</el-button
      >
    </el-row>
    <el-table
      :data="tableData"
      v-loading="loading"
      stripe
      @sort-change="sortChange"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column
        prop="receiverId"
        label="消息状态"
        width="100"
      >
        <template slot-scope="{row}">
            <span v-html="'已发送'" v-if="row.receiverId!=null " style="color:green;"></span>
            <span v-html="'未发送'" v-else style="color:gray;"></span>
        </template>
      </el-table-column>
      <el-table-column
        prop="name"
        label="姓名"
        width="100"
      ></el-table-column>
      <el-table-column
        prop="sex"
        label="性别"
        width="100"
      >
        <template slot-scope="{row}">
          {{row.sex=='1' ? '男' : '女'}}
        </template>
      </el-table-column>
      <el-table-column
        prop="phone"
        label="电话"
        width="120"
      ></el-table-column>
      <el-table-column
        prop="companyName"
        label="单位名称"
        width="200"
      ></el-table-column>
      <el-table-column
        prop="companyStructName"
        label="机构名称"
        width="150"
      ></el-table-column>
      <el-table-column
        prop="roleName"
        label="角色名称"
        width="150"
      ></el-table-column>
    </el-table>
    <el-pagination
      :current-page.sync="pageIndex"
      :total="totalElements"
      :page-sizes="pageSizeList"
      @current-change="changePage"
      @size-change="pageSizeChange"
      layout="total, sizes, prev, pager, next, jumper"
    ></el-pagination>
  </div>
</template>
<script>
import SelectTree from "@/components/SelectTree";
import Constant from "@/constant";
import messageInfoApi from "@/api/base/messageInfo";
import dataDictionaryApi from "@/api/sys/dataDictionary";
import companyInfoApi from "@/api/base/companyInfo";
import companyStructureInfoApi from "@/api/base/companyStructureInfo";
import personRoleInfoApi from "@/api/base/personRoleInfo";
import NProgress from "nprogress"; // progress bar
import "nprogress/nprogress.css"; // progress bar style

export default {
  props:{
    messageId: {
      default: "",
      type: String
    }
  },
  watch: {
    "queryModel.companyId":function(newVal,oldVal){
      var self = this;

      var formData = new FormData();
      formData.append("companyId", newVal);

      //查询单位下机构
      companyStructureInfoApi.list(formData)
      .then(response=>{
          var jsonData = response.data;

          if (jsonData.result) {
            self.structureList = jsonData.data;
          }
      })

      //查询单位下角色
      // personRoleInfoApi.listByCompanyId(formData)
      // .then(response=>{
      //     var jsonData = response.data;

      //     if (jsonData.result) {
      //       self.roleList = jsonData.data;
      //     }
      // })
    }
  },
  data() {
    var self = this;

    return {
      queryModel: {
        companyId: "",
        companyStructureId: "",
        roleId: "",
        personName: ""
      },
      ruleValidate: {
        companyId: [{ required: true, message: "单位不能为空", trigger: "blur" }],
      },
      companyList: [],
      structureList: [],
      roleList: [],
      loading: false,
      tableData: [],
      pageIndex: 1,
      pageSize: 10,
      totalPages: 0,
      totalElements: 0,
      field: "",
      direction: "",
      pageSizeList: [10, 20, 30],
      multipleSelection: [],
      showModal: false,
      modalTitle: "",
      businessKey: "",
      structProps: {
        // 配置项（必选）
        value: "id",
        label: "structureName",
        children: "children"
      }
    };
  },
  methods: {
    changePage(pageIndex) {
      var self = this;

      this.$refs["queryForm"].validate((valid) => {
        if (valid) {
          self.loading = true;

          self.pageIndex = pageIndex;
          var formData = new FormData();

          formData.append("pageIndex", self.pageIndex);
          formData.append("pageSize", self.pageSize);

          formData.append("messageId", self.messageId);
          formData.append("companyId", self.queryModel.companyId);

          if(self.queryModel.companyStructureId!=null){
            formData.append("companyStructureId", self.queryModel.companyStructureId);
          }

          if(self.queryModel.roleId!=null){
          formData.append("roleId", self.queryModel.roleId);
          }

          if(self.queryModel.personName!=null){
            formData.append("personName", self.queryModel.personName);
          }

          messageInfoApi
            .recvPageSearch(formData)
            .then(function (response) {
              self.loading = false;

              var jsonData = response.data.data;

              self.tableData = jsonData.data;
              self.totalPages = jsonData.totalPages;
              self.totalElements = jsonData.recordsTotal;
            })
            .catch((error) => {
              self.loading = false;
            });
        }
      });
    },
    pageSizeChange(pageSize) {
      this.pageSize = pageSize;

      this.$nextTick(() => {
        this.changePage(this.pageIndex);
      });
    },
    sortChange(data) {
      this.field = data.column.field;
      this.direction = data.order == "ascending" ? "asc" : "desc";

      this.changePage(this.pageIndex);
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleReset(name) {
      this.$refs[name].resetFields();
    },
    handleSendAll(record) {
      var self = this;
      var formData = new FormData();

      formData.append("messageId", self.messageId);
      formData.append("companyId", self.queryModel.companyId);
      formData.append("structureId", self.queryModel.structureId);
      formData.append("roleId", self.queryModel.roleId);
      formData.append("name", self.queryModel.name);

      self.$confirm("是否确认全部发送消息?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          messageInfoApi.sendAll(formData).then(function (response) {
            var jsonData = response.data;

            if (jsonData.result) {
              self.changePage(self.pageIndex);

              self.$message({
                type: "success",
                message: "发送成功!",
              });
            }
          });
        });
    },
    handleBatchSend() {
      var self = this;

      var idList = this.multipleSelection
      .filter((record)=>{
        return record.receiverId==null
      })
      .map((record) => {
        return record.id;
      });

      this.$confirm("是否确认对选中人发送消息？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        var formData = new FormData();

        formData.append("messageId", self.messageId);
        formData.append("personIds", idList.join(","));

        messageInfoApi.batchSend(formData).then(function (response) {
          var jsonData = response.data;

          if (jsonData.result) {
            self.changePage(self.pageIndex);

            self.$message({
              type: "success",
              message: "发送成功!",
            });
          }
        });
      });
    }
  },
  created() {
    var self = this;

    companyInfoApi.treeList().then(function(response) {
      var jsonData = response.data;

      if (jsonData.result) {
        self.companyList = jsonData.data;
      }
    });

    //查询角色
    personRoleInfoApi.findAll()
    .then(response=>{
        var jsonData = response.data;

        if (jsonData.result) {
          self.roleList = jsonData.data;
        }
    });
  },
  mounted() {
    // this.changePage(1);
  },
  components: {
    "el-select-tree": SelectTree
  }
};
</script>
<style lang="scss" scoped>
.el-breadcrumb {
  margin: 10px;
  line-height: 20px;
}

.el-divider {
  margin: 5px 0;
}

.demo-form-inline {
  margin-left: 10px;
  text-align: left;
}

.button-group {
  margin-left: 10px;
  text-align: left;
}
</style>