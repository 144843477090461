<template>
  <div>
    <textarea :id="Id"></textarea>
  </div>
</template>
<script>
export default {
  data() {
    const Id = Date.now();
    return {
      Id: Id,
      Editor: null,
      DefaultConfig: {
        language: "zh_CN",
        // GLOBAL
        height: 500,
        theme: "modern",
        menubar: false,
        toolbar: `styleselect | fontselect | formatselect | fontsizeselect | forecolor backcolor | bold italic underline strikethrough | link image  media | table | alignleft aligncenter alignright alignjustify | outdent indent | numlist bullist | preview removeformat  hr | paste undo redo | fullscreen code`,
        plugins: `
            paste
            importcss
            image
            code
            table
            advlist
            fullscreen
            link
            media
            lists
            textcolor
            colorpicker
            hr
            preview
        `,
        theme_advanced_buttons1: "ankupload",
        // CONFIG
        forced_root_block: "p",
        force_p_newlines: true,
        importcss_append: true,
        // CONFIG: ContentStyle 这块很重要， 在最后呈现的页面也要写入这个基本样式保证前后一致， `table`和`img`的问题基本就靠这个来填坑了
        content_style: `
            *                         { padding:0; margin:0; }
            html, body                { height:100%; }
            img                       { max-width:100%; display:block;height:auto; }
            a                         { text-decoration: none; }
            iframe                    { width: 100%; }
            p                         { line-height:1.6; margin: 0px; }
            table                     { word-wrap:break-word; word-break:break-all; max-width:100%; border:none; border-color:#999; }
            .mce-object-iframe        { width:100%; box-sizing:border-box; margin:0; padding:0; }
            ul,ol                     { list-style-position:inside; }
        `,
        insert_button_items: "image link | inserttable",
        // CONFIG: Paste
        paste_retain_style_properties: "all",
        paste_word_valid_elements: "*[*]", // word需要它
        paste_data_images: true, // 粘贴的同时能把内容里的图片自动上传，非常强力的功能
        paste_convert_word_fake_lists: false, // 插入word文档需要该属性
        paste_webkit_styles: "all",
        paste_merge_formats: true,
        nonbreaking_force_tab: false,
        paste_auto_cleanup_on_paste: false,
        // CONFIG: Font
        fontsize_formats: "10px 11px 12px 14px 16px 18px 20px 24px",
        // CONFIG: StyleSelect
        style_formats: [
          {
            title: "首行缩进",
            block: "p",
            styles: { "text-indent": "2em" }
          },
          {
            title: "行高",
            items: [
              { title: "1", styles: { "line-height": "1" }, inline: "span" },
              {
                title: "1.5",
                styles: { "line-height": "1.5" },
                inline: "span"
              },
              { title: "2", styles: { "line-height": "2" }, inline: "span" },
              {
                title: "2.5",
                styles: { "line-height": "2.5" },
                inline: "span"
              },
              { title: "3", styles: { "line-height": "3" }, inline: "span" }
            ]
          }
        ],
        // FontSelect
        font_formats: `
        微软雅黑=微软雅黑;
        宋体=宋体;
        黑体=黑体;
        仿宋=仿宋;
        楷体=楷体;
        隶书=隶书;
        幼圆=幼圆;
        Andale Mono=andale mono,times;
        Arial=arial, helvetica,
        sans-serif;
        Arial Black=arial black, avant garde;
        Book Antiqua=book antiqua,palatino;
        Comic Sans MS=comic sans ms,sans-serif;
        Courier New=courier new,courier;
        Georgia=georgia,palatino;
        Helvetica=helvetica;
        Impact=impact,chicago;
        Symbol=symbol;
        Tahoma=tahoma,arial,helvetica,sans-serif;
        Terminal=terminal,monaco;
        Times New Roman=times new roman,times;
        Trebuchet MS=trebuchet ms,geneva;
        Verdana=verdana,geneva;
        Webdings=webdings;
        Wingdings=wingdings,zapf dingbats`,
        // Tab
        tabfocus_elements: ":prev,:next",
        object_resizing: true,
        // Image
        imagetools_toolbar:
          "rotateleft rotateright | flipv fliph | editimage imageoptions"
      }
    };
  },
  props: {
    value: {
      default: "",
      type: String
    },
    uploadName: {
      default: "upfile",
      type: String
    },
    config: {
      type: Object,
      default: () => {
        return {
          theme: "modern",
          height: 300
        };
      }
    },
    url: {
      default: "",
      type: String
    },
    accept: {
      default: "image/jpg,image/jpeg,image/png,image/gif",
      type: String
    },
    maxSize: {
      default: 1024 * 1024 * 1000,
      type: Number
    },
    withCredentials: {
      default: false,
      type: Boolean
    }
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
    console.log("销毁tinymce");
    this.$emit("on-destroy");

    // remove(selector:tinymce.Editor)
    window.tinymce.remove(`#${this.Id}`);
  },
  methods: {
    setContent(value) {
      if (this.Editor != null) {
        // get(id:String)
        window.tinymce.get(`${this.Id}`).setContent(value);
      } else {
        /* eslint-disable-next-line */
        console.log("setContent before editor init!");
      }
    },
    getContent() {
      if (this.Editor != null) {
        return window.tinymce.get(`${this.Id}`).getContent();
      } else {
        /* eslint-disable-next-line */
        console.log("setContent before editor init!");
        return "";
      }
    },
    init() {
      const self = this;

      this.Editor = window.tinymce.init({
        // 默认配置
        ...this.DefaultConfig,
        // 图片上传
        images_upload_handler: function(blobInfo, success, failure) {
          if (blobInfo.blob().size > self.maxSize) {
            failure("文件体积过大");
          }

          /* eslint-disable-next-line */
          console.log(blobInfo.blob().type);

          if (self.accept.indexOf(blobInfo.blob().type) > 0) {
            uploadPic();
          } else {
            failure("图片格式错误");
          }

          function uploadPic() {
            const xhr = new XMLHttpRequest();
            const formData = new FormData();
            xhr.withCredentials = self.withCredentials;
            xhr.open("POST", self.url);
            xhr.onload = function() {
              if (xhr.status !== 200) {
                // 抛出 'on-upload-fail' 钩子
                self.$emit("on-upload-fail");
                failure("上传失败: " + xhr.status);
                return;
              }

              const json = JSON.parse(xhr.responseText);

              /* eslint-disable-next-line */
              console.log(json);
              success(json.url);

              // 抛出 'on-upload-complete' 钩子
              self.$emit("on-upload-complete", [json, success, failure]);
            };
            formData.append(self.uploadName, blobInfo.blob());
            xhr.send(formData);
          }
        },
        file_picker_types: "file",
        file_picker_callback: function(callback, value, meta) {
          //文件上传
          console.log("文件上传:" + meta.filetype);

          var input = document.createElement("input");
          input.setAttribute("type", "file");

          input.click();
          input.onchange = function() {
            var file = this.files[0];

            const xhr = new XMLHttpRequest();
            const formData = new FormData();
            xhr.withCredentials = self.withCredentials;
            xhr.open("POST", self.url);
            xhr.onload = function() {
              if (xhr.status !== 200) {
                // 抛出 'on-upload-fail' 钩子
                alert("上传失败: " + xhr.status);
                return;
              }

              const json = JSON.parse(xhr.responseText);

              /* eslint-disable-next-line */
              console.log(json);

              var fileName = file.name;
              fileName = fileName.substring(fileName.lastIndexOf("/"));

              callback(json.url, { title: fileName,text:fileName});
            };

            formData.append(self.uploadName, file, file.name);
            xhr.send(formData);

            callback('',{title:'文件上传中...'});
          };
        },
        // prop内传入的的config
        ...this.config,

        // 挂载的DOM对象
        selector: `#${this.Id}`,

        setup: editor => {
          // 抛出 'on-ready' 事件钩子
          editor.on("init", () => {
            self.loading = false;
            self.$emit("on-ready");

            /* eslint-disable-next-line */
            if (self.value != null) {
              editor.setContent(self.value);
            }
          });
          // 抛出 'input' 事件钩子，同步value数据
          editor.on("input change undo redo", () => {
            self.$emit("input", editor.getContent());
          });
        }
      });
    }
  }
};
</script>
