<template>
  <div>
    <el-breadcrumb separator=">">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>
        <a href="#">基础信息</a>
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <a href="/base/message/list">消息管理</a>
      </el-breadcrumb-item>
    </el-breadcrumb>
    <el-divider></el-divider>
    <el-form
      ref="queryForm"
      :model="queryModel"
      inline
      class="demo-form-inline"
    >
      <el-form-item label="标题" prop="title">
        <el-input type="text" size="mini" v-model="queryModel.title"></el-input>
      </el-form-item>
      <el-form-item label="审核状态" prop="status">
        <el-select v-model="queryModel.status" size="mini" placeholder="请选择">
          <el-option value="0">未审核</el-option>
          <el-option value="1">已审核</el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="消息类型" prop="type">
        <el-select v-model="queryModel.type" size="mini" placeholder="请选择">
          <el-option
            v-for="item in typeList"
            :key="item.id"
            :label="item.name"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          size="mini"
          icon="ios-search"
          @click="changePage(1)"
          :loading="loading"
          >查询</el-button
        >&nbsp;
        <el-button
          type="info"
          size="mini"
          style="margin-left: 8px"
          @click="handleReset('queryForm')"
          >重置</el-button
        >&nbsp;
      </el-form-item>
    </el-form>
    <el-divider></el-divider>
    <el-row class="button-group">
      <el-button
        type="primary"
        size="small"
        plain
        icon="el-icon-circle-plus"
        @click="handleAdd"
        >新增</el-button
      >
      <el-button
        type="primary"
        size="small"
        plain
        icon="el-icon-circle-plus"
        :disabled="multipleSelection.length == 0"
        @click="handleBatchDelete"
        >删除选中项</el-button
      >
    </el-row>
    <el-table
      :data="tableData"
      style="min-height: 400px"
      v-loading="loading"
      stripe
      @sort-change="sortChange"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column
        prop="title"
        sort-by="title_"
        label="标题"
        sortable="custom"
        width="180"
      ></el-table-column>
      <el-table-column
        prop="status"
        sort-by="status_"
        label="审核状态"
        sortable="custom"
        width="180"
      >
        <template slot-scope="{row}">
            <el-switch
            disabled
            v-model="row.status"
            active-color="#13ce66"
            inactive-color="#ff4949"
          ></el-switch>
        </template>
      </el-table-column>
      <el-table-column
        prop="typeN"
        sort-by="type_"
        label="消息类型"
        sortable="custom"
        width="180"
      ></el-table-column>
      <el-table-column
        prop="createTime"
        sort-by="create_time"
        label="创建时间"
        sortable="custom"
        width="180"
      ></el-table-column>
      <el-table-column
        prop="updateTime"
        sort-by="update_time"
        label="更新时间"
        sortable="custom"
        width="180"
      ></el-table-column>
      <el-table-column label="操作" fixed="right" width="300">
        <template slot-scope="{ row }">
          <el-link size="mini" type="primary" @click="handleEdit(row)">编辑</el-link>-
          <el-link size="mini" type="primary" @click="handleSendCondition(row)">发送范围</el-link>-
          <el-link size="mini" type="primary" @click="handleSendMsg(row)">发送消息</el-link>-
          <el-link size="mini" type="primary" @click="handleRecvList(row)">接收列表</el-link>-
          <el-link size="mini" type="primary" @click="handleDelete(row)">删除</el-link>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page.sync="pageIndex"
      :total="totalElements"
      :page-sizes="pageSizeList"
      @current-change="changePage"
      @size-change="pageSizeChange"
      layout="total, sizes, prev, pager, next, jumper"
    ></el-pagination>

    <messageInfo-detail
      v-if="showModal"
      :businessKey="businessKey"
      :title="modalTitle"
      @close="onDetailModalClose"
    ></messageInfo-detail>

    <el-dialog
      :visible.sync="showSendModal"
      title="选择发送对象"
      :modal-append-to-body="true"
      append-to-body
      :close-on-click-modal="false"
      style="text-align: left"
      width="1000px"
    >
      <messageInfo-send
        v-if="showSendModal"
        :messageId="businessKey"
      ></messageInfo-send>
    </el-dialog>

    <el-dialog
      :visible.sync="showSendCondModal"
      title="发送范围"
      :modal-append-to-body="true"
      append-to-body
      :close-on-click-modal="false"
      style="text-align: left"
      width="1000px"
    >
      <messageSendCondition-list
        v-if="showSendCondModal"
        :messageId="businessKey"
      ></messageSendCondition-list>
    </el-dialog>

    <el-dialog
      :visible.sync="showRecvModal"
      title="接收人列表"
      :modal-append-to-body="true"
      append-to-body
      style="text-align: left"
      width="1000px"
    >
      <messageReceiver-list
        v-if="showRecvModal"
        :messageId="businessKey"
      ></messageReceiver-list>
    </el-dialog>
  </div>
</template>
<script>
import Constant from "@/constant";
import MessageInfoDetail from "./messageInfo-detail";
import MessageInfoSend from "./messageInfo-send";
import MessageReceiverList from "./messageReceiver-list";
import MessageSendConditionList from './messageSendCondition-list';
import messageInfoApi from "@/api/base/messageInfo";
import dataDictionaryApi from "@/api/sys/dataDictionary";
import NProgress from "nprogress"; // progress bar
import "nprogress/nprogress.css"; // progress bar style

export default {
  data() {
    var self = this;

    return {
      queryModel: {
        title: "",
        status: "",
        type: "",
      },
      typeList: [],
      loading: false,
      tableData: [],
      pageIndex: 1,
      pageSize: 10,
      totalPages: 0,
      totalElements: 0,
      field: "",
      direction: "",
      pageSizeList: [10, 20, 30],
      multipleSelection: [],
      showModal: false,
      modalTitle: "",
      businessKey: "",
      showSendModal: false,
      showRecvModal: false,
      showSendCondModal: false
    };
  },
  methods: {
    changePage(pageIndex) {
      var self = this;
      self.loading = true;

      self.pageIndex = pageIndex;
      var formData = new FormData();

      formData.append("pageIndex", self.pageIndex);
      formData.append("pageSize", self.pageSize);

      formData.append("title", self.queryModel.title);
      formData.append("status", self.queryModel.status);
      formData.append("type", self.queryModel.type);

      if (this.field != null) {
        formData.append("field", this.field);
      }

      if (this.direction != null) {
        formData.append("direction", this.direction);
      }

      messageInfoApi
        .pageList(formData)
        .then(function (response) {
          self.loading = false;

          var jsonData = response.data.data;

          self.tableData = jsonData.data;
          self.totalPages = jsonData.totalPages;
          self.totalElements = jsonData.recordsTotal;
        })
        .catch((error) => {
          self.loading = false;
          // self.$message.error(error + "");
        });
    },
    pageSizeChange(pageSize) {
      this.pageSize = pageSize;

      this.$nextTick(() => {
        this.changePage(this.pageIndex);
      });
    },
    sortChange(data) {
      this.field = data.column.field;
      this.direction = data.order == "ascending" ? "asc" : "desc";

      this.changePage(this.pageIndex);
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleReset(name) {
      this.$refs[name].resetFields();
    },
    handleAdd() {
      this.modalTitle = "新增";
      this.businessKey = "";
      this.showModal = true;
    },
    handleEdit(record) {
      this.modalTitle = "编辑";
      this.businessKey = record.id;
      this.showModal = true;
    },
    handleDelete(record) {
      var self = this;

      self
        .$confirm("是否确认删除?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          messageInfoApi.remove(record.id).then(function (response) {
            var jsonData = response.data;

            if (jsonData.result) {
              // var index = self.tableData.indexOf(record);
              // self.tableData.splice(index, 1);
              self.changePage(self.pageIndex);

              self.$message({
                type: "success",
                message: "删除成功!",
              });
            }
          });
        });
    },
    handleBatchDelete() {
      var self = this;

      var idList = this.multipleSelection.map((record) => {
        return record.id;
      });

      this.$confirm("是否确认删除选中项？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        messageInfoApi.batchRemove(idList).then(function (response) {
          var jsonData = response.data;

          if (jsonData.result) {
            self.changePage(self.pageIndex);

            self.$message({
              type: "success",
              message: "删除成功!",
            });
          }
        });
      });
    },
    handleSendMsg(row) {
      this.businessKey = row.id;      
      this.showSendModal = true;
    },
    handleSendCondition(row) {
      this.businessKey = row.id;      
      this.showSendCondModal = true;
    },
    handleRecvList(row) {
      this.businessKey = row.id;      
      this.showRecvModal = true;
    },
    onDetailModalClose(refreshed) {
      //保存成功后回调
      this.showModal = false;

      if (refreshed) {
        this.changePage(this.pageIndex);
      }
    },
  },
  mounted: function () {
    dataDictionaryApi
      .findByCatalogName({
        catalogName: "消息类型",
      })
      .then((response) => {
        var jsonData = response.data;
        this.typeList = jsonData.data;
      });

    this.changePage(1);
  },
  components: {
    "messageInfo-detail": MessageInfoDetail,
    "messageInfo-send" : MessageInfoSend,
    "messageReceiver-list": MessageReceiverList,
    "messageSendCondition-list": MessageSendConditionList
  },
};
</script>
<style lang="scss" scoped>
.el-breadcrumb {
  margin: 10px;
  line-height: 20px;
}

.el-divider {
  margin: 5px 0;
}

.demo-form-inline {
  margin-left: 10px;
  text-align: left;
}

.button-group {
  margin-left: 10px;
  text-align: left;
}
</style>